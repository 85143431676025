//variables
@import "variables";

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.5;

    &::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 0;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar
    {
        width: 5px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb
    {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #8c8c8c;
    }
}

.bold-text{
    font-weight: 700;
}

.text-center{
    text-align: center;
}

.img-responsive {
    max-width: 100%;
    height: auto;
    display: block;
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 5px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.reserve-btn {
    background-color: #689f38;
    color: $white;
}
.delete-btn {
    background-color: #dc3545;
    color: $white;
}
.update-btn {
    background-color: #f0ad4e;
    color: $white;
}

.back-btn-link{
    width: 20px;
    position: absolute;
    top: 25px;
    left: 8px;
    z-index: 100;
}

/*left container component styles*/
.left-container {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.main-app {
    background-color: rgba(0, 0, 0, 0.9);
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &::before {
        background-color: rgba(0, 0, 0, 0.55);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        content: "";
    }
}

/*right container component styles*/
.right-container {
    background-color: rgba(124, 193, 64, 0.81);
    position: relative;

    .meeting-room-select {
        color: $white;
        position: absolute;
        left: 20px;
        top: 20px;

        &::before,
        &::after {
            display: none;
        }

        &:focus {
            background-color: transparent;
        }

        .MuiSelect-select:focus {
            background-color: transparent;
        }

        .MuiSelect-icon {
            color: $white;
        }
    }

    .navigation-links {
        position: absolute;
        top: 20px;
        right: 20px;

        .navigation-link {
            display: inline-block;
            vertical-align: middle;
            width: 25px;
            margin-left: 20px;
        }
    }
}

.right-container-inner-wrapper {
    padding-top: 60px;
}

.menu-icon-component {
    position: fixed;
    right: 10px;
    top: 18px;
    z-index: 10;
    a {
        display: block;
        padding: 10px;
        img {
            width: 25px;
        }
    }
}

.event-actions {
    padding: 20px;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);

    .reserve-btn {
        background-color: $white;
        color: #4e4e4e;
        font-size: 18px;
        text-decoration: none;
        padding: 5px 40px;

        &:hover {
            text-decoration: none;
            color: #7cc140;
        }
    }
}

//color change
.main-app.on-meeting {
    .now-next-title h1 {
        color: #b61636;
    }
    .right-container {
        background-color: rgba(199, 0, 39, 0.8);
    }
    .today-info-component .time-message {
        color: #ff5172;
    }

    .events-list-wrapper
        .event-group-component
        .events-list-items
        li.events-list-item
        .list-left-container
        .event-organizer {
        color: #ff5172;
    }

    .event-actions .reserve-btn:hover {
        color: #ff5172;
    }
}

//circular progress
.MuiCircularProgress-colorPrimary{
    color: #ffffff !important;
}

/* Small */
@media only screen and (min-width: 576px) {
}

/* Medium - small devices, tablets */
@media only screen and (min-width: 768px) {
    .header {
        background-color: transparent;
    }
    .main-app {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        min-height: 100vh;
        min-width: 0px;
    }

    .left-container {
        flex-basis: 60%;
        width: 0;
        position: relative;
        background-image: none !important;
    }

    .right-container {
        min-height: 100%;
        flex-basis: 40%;
        width: 0;
        padding-top: 70px;
        position: relative;
    }

    .right-container-inner-wrapper {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 0;
    }

    .right-section-1 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 160px;
    }

    .right-section-3 {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0px;
        overflow-y: scroll;

        .events-component {
            padding-bottom: 0;
        }

        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 0;
            background-color: #F5F5F5;
        }
        &::-webkit-scrollbar
        {
            width: 5px;
            background-color: #F5F5F5;
        }
        &::-webkit-scrollbar-thumb
        {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #8c8c8c;
        }
    }
    .right-section-4 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 50px;

        .event-actions {
            padding: 20px 30px 20px;
            text-align: right;
            position: relative;
            bottom: inherit;
            left: inherit;
            right: inherit;
            background-color: transparent;
        }
    }
}

/* Large - medium devices, desktops */
@media only screen and (min-width: 992px) {
}

/* Extra large - large devices, wide screens */
@media only screen and (min-width: 1200px) {
}
