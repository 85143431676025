.loading-page {
    height: 100%;
    background-color: #ffffff;
}

.MuiLinearProgress-root {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
}
