.item-list{

    .list-item{
        flex: 1 1 auto;
        min-width: 0;
        margin-top: 6px;
        margin-bottom: 6px;
        padding-left: 24px;
        padding-right: 24px;

        .title-name{
            font-size: 1rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.00938em;
        }

        .text-value{
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.4;
            color: rgba(0, 0, 0, 0.54);
            margin-top: 5px;
        }

        .item-description-html-view{
            ul{
                list-style: disc;
                margin-bottom: 10px;
                li{
                    margin-left: 15px;
                }
            }
            ol{
                list-style: decimal;
                margin-bottom: 10px;
    
                li{
                    margin-left: 15px;
                }
            }
            
            p{
                margin-bottom: 10px;
            }
    
            sup{
                vertical-align: super;
            }
            sub{
                vertical-align: sub;
            }
    
            code{
                font-family: monospace;
                overflow-wrap: break-word;
                background: rgb(241, 241, 241);
                border-radius: 3px;
                padding: 1px 3px;
            }
    
            .MuiChip-root{
                margin-right: 5px;
                margin-bottom: 5px;
            }
        }

        .item-attendees{
            ul{
                list-style: disc;
                margin-bottom: 10px;
                li{
                    margin-left: 0;
                    display: inline-block;
                    width: 100%;    
                    margin-bottom: 5px;
                    &:before { 
                        content:'\ffed'; 
                        margin-right:0.5em; 
                    }

                    @media only screen and (min-width: 768px) {
                        width: 50%;
                    }
                }
            }
        }
    }
}

.single-meeting-popup{

    .navigation-link.edit-icon{
        position: absolute;
        right: 15px;
        top: 20px;
        background-color: #ec971f;
        border-radius: 5px;
        padding: 5px;
        font-size: 0;

        @media only screen and (min-width: 768px) {
            right: 50px;
            top: 20px;
        }
    }
    .navigation-link.delete-icon{
        position: absolute;
        right: 15px;
        top: 55px;
        background-color: #d9534f;
        border-radius: 5px;
        padding: 5px;
        font-size: 0;

        @media only screen and (min-width: 768px) {
            right: 15px;
            top: 20px;
        }
    }

    .navigation-link{
        svg{
            fill: #ffffff;
            width:20px;
            height: 20px;
        }
    }

    .MuiTypography-h6{
        padding-right: 35px;
        
        @media only screen and (min-width: 768px) {
            padding-right: 70px;
        }
    }
}