@import "../variables";

//event list
.events-component {
    padding-bottom: 100px;
}

.message-component {
    color: $white;
    font-size: 1.125rem;
    font-weight: 400;
    text-align: left;
    padding: 15px 20px;

    .circular-progress {
        margin-left: 10px;
    }
}

.events-list-wrapper {
    .event-group-component {
        .event-date-text {
            font-size: 0.6875rem;
            font-weight: 700;
            color: $black;
            background-color: rgba(255, 255, 255, 0.5);
            padding: 1em;
        }
        .events-list-items {
            padding: 0 15px;
            li.events-list-item {
                display: flex;
                padding: 10px;
                cursor: pointer;

                &:not(:nth-of-type(1)) {
                    border-top: 1px solid rgba(255, 255, 255, 0.5);
                }

                .list-left-container {
                    flex-basis: 70%;
                    .event-name {
                        font-size: 12px;
                        color: $white;
                    }
                    .event-organizer {
                        font-size: 12px;
                        color: #2b5b00;
                    }
                }
                .list-right-container {
                    flex-basis: 30%;

                    .event-duration {
                        font-size: 12px;
                        color: rgba(255, 255, 255, 0.7);
                        text-align: right;
                    }
                }
            }
        }
    }
}
