@import "../variables";

//today info component
.today-info-component {
    .time-wrapper {
        text-align: center;
        font-size: 0;
        span {
            font-size: 68px;
            color: $white;
            font-weight: 700;
            display: inline-block;
        }
        span.seconds-wrapper {
            font-size: 18px;
            font-weight: 400;
            display: inline-block;
        }
    }

    .date-wrapper {
        color: $white;
        font-size: 1.125rem;
        font-weight: 400;
        text-align: center;
    }

    .time-message {
        color: #2b5b00;
        font-size: 0.75rem;
        font-weight: 400;
        text-align: center;
    }
}
